import React from 'react';
import refreshIcon from '../images/refresh-icon.png';

function UpcomingReviewsScheduleDisplay({ upcomingReviews, onRefreshClick }) {
  const getPeriodLabel = (period) => {
    switch (period) {
      case 'thirtyMinutes': return 'Next 30 Minutes';
      case 'fourHours': return 'Next 4 Hours';
      case 'oneDay': return '1 Day';
      case 'threeDays': return '3 Days';
      case 'sevenDays': return '7 Days';
      case 'thirtyDays': return '30 Days';
      default: return period;
    }
  };

  return (
    
    <div className="w-full bg-white p-4 rounded-lg shadow-blueBoxShadow flex flex-col justify-between">
      <div className="flex items-center mb-6 justify-between">
        <p className="text-left text-xl sm:text-2xl md:text-4xl font-bold">Upcoming Reviews</p>
        <img
          src={refreshIcon}
          alt="Refresh"
          className="w-4 h-4 sm:w-6 sm:h-6 ml-2 cursor-pointer transition-transform duration-300"
          onClick={() => {
            const icon = document.getElementById('refresh-icon');
            icon.classList.add('animate-spin');
            onRefreshClick();
            setTimeout(() => {
              icon.classList.remove('animate-spin');
            }, 5000);
          }}
          id="refresh-icon"
        />
      </div>
      <div className="flex flex-col items-start w-full flex-grow justify-between">
        {['thirtyMinutes', 'fourHours', 'oneDay', 'threeDays', 'sevenDays', 'thirtyDays'].map((period) => {
          const value = upcomingReviews[period] || 0;
          const textColor = value === 0 ? 'text-lightOrangeText' : 'text-lightBlueText';
          const bgColor = value === 0 ? 'bg-lightOrangeBackground' : 'bg-lightBlueBackground';
          return (
            <p key={period} className="text-xs sm:text-sm md:text-md flex justify-between w-full mb-2 sm:mb-0">
              <span className="font-semibold">{getPeriodLabel(period)}</span>
              <span className={`ml-auto font-bold ${textColor} ${bgColor} rounded-md px-1 sm:px-2 sm:mx-2 sm:my-1 py-1`}>+{value}</span>
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default UpcomingReviewsScheduleDisplay;
