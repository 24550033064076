import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';

import Leaderboard from '../components/leaderboard';
import { fetchAllLeaderboards } from '../components/utils/fetchLeaderboard';

//data querying imports
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useQuery, useQueryClient } from '@tanstack/react-query';

//loaders
import { MoonLoader } from 'react-spinners';

//react heatmap
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import '../styles/heatmap.css';

function TotalStats() {
    const navigate = useNavigate();
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const [loadingState, setLoadingState] = useState(true);
    const [leaderboardLimit, setLeaderboardLimit] = useState(5);

    const fetchUserUsageStats = async () => {
        if (!user) return {};
        const db = getFirestore();
        const userStatsRef = collection(db, 'userProgress', user.uid, 'userStats');
        const q = query(userStatsRef);
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const userStatsData = querySnapshot.docs[0].data(); // Assuming there's only one document
            return userStatsData;
        }
        console.log('User Usage Stats not found');
        return {};
    };

    // UseQuery to fetch user stats data and leaderboards, and handle loading state
    const { data, isLoading } = useQuery({
        queryKey: ['userStats', user?.uid, leaderboardLimit],
        queryFn: async () => {
            const userStats = await fetchUserUsageStats();
            const leaderboards = await fetchAllLeaderboards(leaderboardLimit);
            return { ...userStats, leaderboards };
        },
        enabled: !!user,
        refetchOnWindowFocus: false, // Don't refetch data on screen blur
        onSuccess: () => {
            // Data fetching is successful, now handle the loading state
            setTimeout(() => {
                setLoadingState(false);
            }, 2000); // Ensure a minimum of 2 seconds loading
        },
    });

    // useEffect to manage user loading and loading state
    useEffect(() => {
        document.title = "OniKanji - Total Stats";
        if (!userLoading && !user) {
            navigate('/login');
        } else if (!userLoading && user) {
            // If user is loaded, start the loading state timer
            setLoadingState(true);
            setTimeout(() => {
                if (!isLoading) {
                    setLoadingState(false);
                }
            }, 2000); // Ensure a minimum of 2 seconds loading

            console.log(data);
        }
    }, [user, userLoading, navigate, isLoading]);

    const adjustToPreviousSunday = (date) => {
        const day = date.getDay();
        const diff = date.getDate() - day;
        return new Date(date.setDate(diff));
    };

    const adjustToNextSaturday = (date) => {
        const day = date.getDay();
        const diff = 6 - day;
        return new Date(date.setDate(date.getDate() + diff));
    };

    const heatMapStartDate = data?.heatMapStartDate
        ? new Date(data.heatMapStartDate.seconds * 1000 + data.heatMapStartDate.nanoseconds / 1000000)
        : null;
    const adjustedStartDate = heatMapStartDate ? adjustToPreviousSunday(new Date(heatMapStartDate)) : new Date();
    const threeHundredSixtyFiveDaysFromNow = new Date(adjustedStartDate);
    threeHundredSixtyFiveDaysFromNow.setDate(adjustedStartDate.getDate() + 365);
    const adjustedEndDate = adjustToNextSaturday(new Date(threeHundredSixtyFiveDaysFromNow));

    // Ensure the end date completes the week
    const completeWeekEndDate = new Date(adjustedEndDate);
    completeWeekEndDate.setDate(adjustedEndDate.getDate() + (7 - adjustedEndDate.getDay()));

    const formatDate = (date) => date.toISOString().split('T')[0]; // Format date to "YYYY-MM-DD"

    // Convert heatMapUsage to an array of values
    const convertHeatMapUsageToValues = (heatMapUsage = {}) => {
        return Object.entries(heatMapUsage).map(([date, count]) => ({
            date: new Date(date).toISOString().split('T')[0], // Ensure date is in YYYY-MM-DD format
            count: count || 0,
        }));
    };

    const heatMapValues = data ? convertHeatMapUsageToValues(data.heatMapUsage) : [];

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const totalKanjiCounts = {
        N5: 80,
        N4: 240,
        N3: 640,
        N2: 1006,
        N1: 2136,
    };

    const handleSeeMore = () => {
        setLeaderboardLimit(prev => prev === 5 ? 10 : 5); 
    };

    // Just before the return statement, compute these values if data is available
    let dailyReviewAverage = 0;
    let activeDays = 0;
    let longestStreak = 0;
    let currentStreak = 0;

    if (data && data.heatMapUsage && heatMapStartDate) {
        // Convert heatMapUsage object to array and sort by date
        const usageArray = Object.entries(data.heatMapUsage)
            .map(([dateStr, count]) => ({
                date: new Date(dateStr),
                count: count || 0
            }))
            .sort((a, b) => a.date - b.date);

        // Calculate total reviews
        const totalReviews = usageArray.reduce((sum, day) => sum + day.count, 0);

        // Days since start
        const startDate = new Date(data.heatMapStartDate.seconds * 1000 + data.heatMapStartDate.nanoseconds / 1000000);
        const daysSinceStart = Math.floor((Date.now() - startDate) / (1000 * 60 * 60 * 24));

        // Daily Review Average
        dailyReviewAverage = daysSinceStart > 0 ? (totalReviews / daysSinceStart).toFixed(2) : "0.00";

        // Active Days: count days with count > 0
        activeDays = usageArray.filter((day) => day.count > 0).length;

        // Longest Streak & Current Streak
        let streak = 0;
        longestStreak = 0;

        for (let i = 0; i < usageArray.length; i++) {
            if (usageArray[i].count > 0) {
                streak++;
                if (streak > longestStreak) {
                    longestStreak = streak;
                }
            } else {
                streak = 0;
            }
        }

        // Current Streak: count backward from the last day
        currentStreak = 0;
        for (let i = usageArray.length - 1; i >= 0; i--) {
            if (usageArray[i].count > 0) {
                currentStreak++;
            } else {
                break;
            }
        }
    }

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} onSignOut={handleSignOut} subscriptionStatus={subscriptionStatus} />

            <div className="bg-mainBackgroundColor min-h-screen flex flex-col items-center p-4">
                <div className="max-w-screen-lg w-full bg-mainBackgroundColor p-6 mt-2">

                    <div className="max-w-screen-lg w-full bg-white p-6 mt-10 shadow-blueBoxShadow rounded-lg">
                        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                            <p className="text-xl font-bold mb-4 sm:mb-0">You Started Your Kanji Journey {data?.heatMapStartDate ? Math.floor((new Date() - new Date(data.heatMapStartDate.seconds * 1000 + data.heatMapStartDate.nanoseconds / 1000000)) / (1000 * 60 * 60 * 24)) : 'N/A'} Days Ago</p>
                            <p>Last Refreshed: {data?.lastRefresh ? new Date(data.lastRefresh.seconds * 1000 + data.lastRefresh.nanoseconds / 1000000).toLocaleDateString() : 'N/A'}</p>
                        </div>
                        {loadingState || isLoading ? (
                            <div className="max-w-screen-lg w-full bg-white p-6 mt-10 flex justify-center">
                                <MoonLoader color="#009BCE" size={60} />
                            </div>
                        ) : (
                            <div className="overflow-x-auto mt-4">
                                <div className="min-w-[750px]">
                                    <CalendarHeatmap
                                        startDate={formatDate(adjustedStartDate)}
                                        endDate={formatDate(completeWeekEndDate)}
                                        values={heatMapValues}
                                        showMonthLabels={true}
                                        showWeekdayLabels={false}
                                        gutterSize={2}
                                        classForValue={(value) => {
                                            if (!value || value.count === 0) {
                                                return 'color-empty';
                                            }
                                            const scale = Math.ceil((value.count / 100) * 10);
                                            const boundedScale = Math.min(Math.max(scale, 1), 10);
                                            return `color-scale-${boundedScale}`;
                                        }}
                                        titleForValue={(value) => {
                                            if (!value || value.count === 0) {
                                                return 'No reviews on this day';
                                            }
                                            const date = new Date(value.date);
                                            const formattedDate = date.toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            });
                                            return `${value.count} Reviews on ${formattedDate}`;
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col sm:flex-row justify-between mt-4">
                            <div className="text-center mb-4 sm:mb-0">
                                <p className="text-base font-bold">Daily Review Average</p>
                                <p className="text-xl text-darkBlueColor">{dailyReviewAverage}</p>
                            </div>
                            <div className="text-center mb-4 sm:mb-0">
                                <p className="text-base font-bold">Active Days</p>
                                <p className="text-xl text-darkBlueColor">{activeDays}</p>
                            </div>
                            <div className="text-center mb-4 sm:mb-0">
                                <p className="text-base font-bold">Current Streak</p>
                                <p className="text-xl text-darkBlueColor">{currentStreak}</p>
                            </div>
                            <div className="text-center">
                                <p className="text-base font-bold">Longest Streak</p>
                                <p className="text-xl text-darkBlueColor">{longestStreak}</p>
                            </div>
                        </div>
                    </div>

                    <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                        <h2 className="text-xl font-bold mb-4">JLPT Tracker</h2>
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4">
                            {['N5', 'N4', 'N3', 'N2', 'N1'].map((level) => {
                                const jlptCounts = data?.jlptCounts || { N1: 0, N2: 0, N3: 0, N4: 0, N5: 0 };
                                const count = jlptCounts[level] || 0;
                                const percentage = ((count / totalKanjiCounts[level]) * 100).toFixed(2); 
                                return (
                                    <div key={level} className="bg-lightBlueBackground p-4 rounded-lg text-center text-lightBlueText">
                                        <h3 className="text-lg font-semibold">{level}</h3>
                                        <p className="text-sm mt-2">{percentage}% Complete</p> 
                                    </div>
                                );
                            })}
                        </div>
                    </div>


                    <div className="max-w-screen-lg w-full bg-white p-6 mt-10 shadow-blueBoxShadow rounded-lg">
                        <h2 className="text-xl font-bold mb-4">Kanji Stats</h2>

                        {/* Divider line */}
                        <hr className="my-6 border-t-2 border-gray-300" />

                        {/* Three cards */}
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                            <div className="bg-lightOrangeBackground p-6 rounded-lg text-center">
                                <h3 className="text-lg mb-2 font-bold">Your Arch Nemesis</h3>
                                <p className="text-3xl font-bold text-darkOrangeColor">{data?.archNemisis}</p>
                            </div>
                            <div className="bg-lightOrangeBackground p-6 rounded-lg text-center">
                                <h3 className="text-lg mb-2 font-bold">Dominating</h3>
                                <p className="text-3xl font-bold text-darkOrangeColor">{data?.dominatingKanji}</p>
                            </div>
                            <div className="bg-lightOrangeBackground p-6 rounded-lg text-center">
                                <h3 className="text-lg mb-2 font-bold">Assassin</h3>
                                <p className="text-3xl font-bold text-darkOrangeColor">{data?.assassin}</p>
                            </div>
                        </div>

                        <hr className="my-6 border-t-2 border-gray-300" />
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <div className="bg-lightOrangeBackground p-4 rounded-lg text-center">
                                <h2 className="text-2xl font-bold text-darkOrangeColor">{data?.totalSlayedKanjiCount}</h2>
                                <span className="text-sm font-bold">Total Kanji Slayed</span>
                            </div>
                            <div className="bg-lightOrangeBackground p-4 rounded-lg text-center">
                                <h2 className="text-2xl font-bold text-darkOrangeColor">{data?.totalReviewMinutes}</h2>
                                <span className="text-sm font-bold">Total Review Minutes</span>
                            </div>
                            <div className="bg-lightOrangeBackground p-4 rounded-lg text-center">
                                <h2 className="text-2xl font-bold text-darkOrangeColor">{(data?.totalAccuracy * 100).toFixed(2)}%</h2>
                                <span className="text-sm font-bold">Total Accuracy</span>
                            </div>
                        </div>
                    </div>

                    <div className="mt-10">
                        <Leaderboard 
                            currentPage="my-stats"
                            totalAccuracyData={data?.leaderboards?.userStats || []}
                            speedRunData={data?.leaderboards?.speedRun || []}
                            hallOfShameData={data?.leaderboards?.hallOfShame || []}
                            onSeeMore={handleSeeMore}
                            isExpanded={leaderboardLimit > 5}
                        />
                    </div>



                </div>
            </div>
        </div>
    );
}

export default TotalStats;