import { useQuery } from '@tanstack/react-query';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export const useKanjiByStatus = (uid, selectedStatuses) => {
    return useQuery({
        queryKey: ['kanjiByStatus', uid, selectedStatuses],
        queryFn: async () => {
            if (!uid || !selectedStatuses.length) return [];

            try {
                const db = getFirestore();
                const userProgressRef = collection(db, 'userProgress', uid, 'reviewKanji');
                
                const q = query(
                    userProgressRef,
                    where('queStatus', 'in', selectedStatuses)
                );

                const querySnapshot = await getDocs(q);
                
                return querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    kanji: doc.data().kanji,
                    status: doc.data().queStatus
                }));
            } catch (error) {
                console.error('Error fetching kanji by status:', error);
                throw error;
            }
        },
        enabled: !!uid && selectedStatuses.length > 0,
        staleTime: 5 * 60 * 1000, // Cache for 5 minutes
        retry: 2
    });
};