// renderUtils.js
export const renderOnScreenValue = (value, associatedKanji, inputType, furiganaMap) => {
    if (!value) return null;
  
    // Check if furiganaMap is available and has data
    if (furiganaMap && furiganaMap.length > 0) {
      return (
        <span>
          {furiganaMap.map((item, index) => {
            const { kanji, furigana } = item;
            let content;
  
            if (furigana) {
              content = (
                <span
                  key={index}
                  className="relative group cursor-default border-b-2 border-gray-700"
                  style={{ textDecorationColor: '#9CA3AF' }}
                >
                  {kanji}
                  <span
                    className="absolute bottom-full left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity text-2xl text-gray-700 whitespace-nowrap"
                    style={{ marginBottom: '-1em' }}
                  >
                    {furigana}
                  </span>
                </span>
              );
            } else {
              content = <span key={index}>{kanji}</span>;
            }
  
            if (inputType === 'multipleChoice' && kanji === associatedKanji) {
              return (
                <span key={index} className="border-b-2 border-oniKanjiPink">
                  {content}
                </span>
              );
            } else {
              return content;
            }
          })}
        </span>
      );
    } else {
      // If furiganaMap is not available, use your existing logic
      if (inputType === 'multipleChoice' && value.length > 1) {
        return value.split('').map((char, index) => {
          if (char === associatedKanji) {
            return (
              <span key={index} className="border-b-2 border-oniKanjiPink">
                {char}
              </span>
            );
          }
          return <span key={index}>{char}</span>;
        });
      }
      return value;
    }
  };
  