export function generateSSMLText(sentence, furiganaSampleSentence, vocabWord) {
    if (!sentence) {
      return sentence;
    }
  
    // Remove explanation in parentheses from the end of the sentence
    sentence = sentence.replace(/\s*\(.*?\)\s*$/, '');
  
    let furiganaData;
  
    // Check if the sentence uses the // format
    if (sentence.includes('//')) {
      furiganaData = [];
      const parts = sentence.split('//');
      let plainSentence = '';
  
      // Start from the first part (which is text) and process pairs
      plainSentence += parts[0]; // Add the first text segment
  
      // Process pairs of furigana and text
      for (let i = 1; i < parts.length; i += 2) {
        const furigana = parts[i];
        // Get the preceding text segment to find the kanji
        const prevText = parts[i - 1];
  
        // Find all kanji characters in the preceding text
        const kanjiMatch = prevText.match(/[\u4E00-\u9FAF]+/g);
        const kanji = kanjiMatch ? kanjiMatch[kanjiMatch.length - 1] : prevText.slice(-1);
  
        // Replace the kanji with a <phoneme> tag
        const phonemeTag = `<phoneme alphabet="yomigana" ph="${furigana}">${kanji}</phoneme>`;
        plainSentence = plainSentence.replace(kanji, phonemeTag);
  
        // Add the next text segment if it exists
        if (parts[i + 1]) {
          plainSentence += parts[i + 1];
        }
      }
  
      // Update the sentence to the SSML version
      sentence = plainSentence;
    } else if (furiganaSampleSentence) {
      // Original JSON format handling
      try {
        furiganaData = JSON.parse(furiganaSampleSentence.replace(/'/g, '"'));
  
        // Replace kanji words with <phoneme> tags
        furiganaData.forEach((item) => {
          const kanji = Object.keys(item)[0];
          const reading = item[kanji];
          const regex = new RegExp(kanji, 'g');
          const phonemeTag = `<phoneme alphabet="yomigana" ph="${reading}">${kanji}</phoneme>`;
          sentence = sentence.replace(regex, phonemeTag);
        });
      } catch (error) {
        console.error('Error parsing furigana data:', error);
        return sentence;
      }
    } else {
      return sentence;
    }
  
    // Wrap the sentence in <speak> tags
    const ssmlWrappedText = `<speak>${sentence}</speak>`;
    return ssmlWrappedText;
  }
  