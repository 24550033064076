import React, { useEffect } from 'react';
import refreshIcon from '../images/refresh-icon.png';
import oniKanjiMikanReviews from '../images/appHomeAssets/onikanji-review-button-graphic.webp';
import oniKanjiMikanNewLessons from '../images/appHomeAssets/onikanji-learn-button-graphic.webp';
import oniKanjiMikanExtraStudy from '../images/appHomeAssets/onikanji-extra-study-button-graphic.webp';


function DashboardSummary({ newLessons, reviews, onNewLessonClick, onReviewsClick }) {

  return (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-6 sm:mt-10">
      <div
        className="w-full md:w-1/3 bg-lightOrangeBackground p-4 rounded-lg shadow-blueBoxShadow flex flex-col justify-between items-center relative overflow-hidden cursor-pointer transition-all duration-300 hover:bg-darkOrangeColor group"
        onClick={onNewLessonClick}
      >
        <div className="absolute inset-0 w-full h-full bg-lightOrangeBackground group-hover:bg-darkOrangeColor transition-colors duration-300"></div>

        <p className="text-center text-2xl md:text-4xl font-bold text-lightOrangeText relative z-10 group-hover:text-white transition-colors duration-300">New Lessons</p>

        <p className="text-center text-5xl md:text-7xl font-bold text-lightOrangeText my-4 relative z-10 group-hover:text-white transition-colors duration-300">{newLessons}</p>
        <div className="relative z-10"><img src={oniKanjiMikanNewLessons} alt="" className="w-48 h-48 object-cover" /></div>
      </div>
      <div
        className="w-full md:w-1/3 bg-lightBlueBackground p-4 rounded-lg shadow-blueBoxShadow flex flex-col justify-between items-center relative overflow-hidden cursor-pointer transition-all duration-300 hover:bg-darkBlueColor group"
        onClick={onReviewsClick}
      >
        <div className="absolute inset-0 w-full h-full bg-lightBlueBackground group-hover:bg-darkBlueColor transition-colors duration-300"></div>

        <p className="text-center text-2xl md:text-4xl font-bold text-lightBlueText relative z-10 group-hover:text-white transition-colors duration-300">Reviews</p>
        <p className="text-center text-5xl md:text-7xl font-bold text-lightBlueText my-4 relative z-10 group-hover:text-white transition-colors duration-300">{reviews}</p>
        <div className="relative z-10"><img src={oniKanjiMikanReviews} alt="" className="w-48 h-48 object-cover" /></div>
      </div>

      <div
        className="w-full md:w-1/3 bg-coral p-4 rounded-lg shadow-blueBoxShadow flex flex-col justify-between items-center relative overflow-hidden cursor-pointer transition-all duration-300 hover:bg-coralHover group"
        onClick={() => window.location.href = '/extra-study'}
      >
        <div className="absolute inset-0 w-full h-full bg-coral group-hover:bg-coralHover transition-colors duration-300"></div>
        <p className="text-center text-2xl md:text-4xl font-bold text-coralText relative z-10 group-hover:text-white transition-colors duration-300">Extra Study</p>
        <p className="text-center text-sm md:text-base font-bold text-coralText my-4 relative z-10 group-hover:text-white transition-colors duration-300">Practice your mistakes, critical kanji, JLPT lists, and more</p>
        <div className="relative z-10"><img src={oniKanjiMikanExtraStudy} alt="" className="w-48 h-48 object-cover" /></div>
      </div>
    </div>
  );
}

export default DashboardSummary;