import { useQuery } from '@tanstack/react-query';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const useUserLevel = (uid) => {
    return useQuery({
        queryKey: ['userLevel', uid],
        queryFn: async () => {
            if (!uid) return null;
            
            const db = getFirestore();
            const userProgressRef = doc(db, 'userProgress', uid);
            const userProgressDoc = await getDoc(userProgressRef);
            
            if (!userProgressDoc.exists()) {
                return null;
            }
            
            return userProgressDoc.data().currentLevel || 1;
        },
        enabled: !!uid
    });
};