import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';

import { createCallableFunction } from '../config/firebase';

// Critical Condition Kanji
import { CriticalKanjiDisplay } from './criticalConditionKanjiDisplay';

// OniKanji Level Selection
import { OniKanjiLevelDisplay } from './oniKanjiDisplayLevel';

// JLPT Level Selection
import { JLPTKanjiDisplay } from './jlptKanjiDisplay';

// Kanji Status Selection
import { KanjiStatusDisplay } from './kanjiByStatusDisplay';

//hooks
import { useUserLevel } from './hooks/getUserLevel';
import { useKanjiByLevels } from './hooks/fetchKanjiByLevel';
import { useKanjiByJLPT } from './hooks/fetchKanjiByJLPT';
import { useCriticalKanji } from './hooks/criticalConditionHook';
import { useKanjiByStatus } from './hooks/fetchKanjiByStatus';

//utils
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ClipLoader
import ClipLoader from 'react-spinners/ClipLoader';

//footer
import Footer from '../components/footer';


function ExtraStudySelectionScreen() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const { data: userLevel, isLoading: levelLoading } = useUserLevel(user?.uid);
    const { data: criticalKanji, isLoading: isCriticalKanjiLoading, error: criticalKanjiError } = useCriticalKanji(user?.uid);



    const [selectedType, setSelectedType] = useState('Critical Condition Kanji');

    // Add this state for level selection
    const [excludedLevels, setExcludedLevels] = useState([]);


    const [excludedQuestionTypes, setExcludedQuestionTypes] = useState([]);
    const [excludedAnswerTypes, setExcludedAnswerTypes] = useState([]);
    const [showFlashcard, setShowFlashcard] = useState(true);


    useEffect(() => {
        document.title = "OniKanji - Extra Study";
        if (!userLoading && !user) {
            navigate('/login');
        }
    }, [user, userLoading, navigate]);

    // Add this handler
    const handleLevelToggle = (level) => {
        setExcludedLevels(prev =>
            prev.includes(level)
                ? prev.filter(l => l !== level)
                : [...prev, level]
        );
    };

    // Convert excludedLevels to includedLevels
    const includedLevels = [...Array(userLevel || 0)]
        .map((_, index) => index + 1)
        .filter(level => !excludedLevels.includes(level));



    //Critical Condition Kanji
    const [criticalKanjiList, setCriticalKanjiList] = useState([]);

    useEffect(() => {
        if (criticalKanji && Array.isArray(criticalKanji)) {
            // Extract the kanji characters
            const kanjiCharacters = criticalKanji.map(k => k.kanji?.slice(0, 1)).filter(Boolean);
            setCriticalKanjiList(kanjiCharacters);
        }
    }, [criticalKanji]);


    // Fetch kanji data when OniKanji Level is selected
    const { data: levelKanji, isLoading: isKanjiLoading, error } = useKanjiByLevels(
        selectedType === 'OniKanji Level' ? includedLevels : []
    );

    // JLPT Level Selection
    const [jlptLevels, setJlptLevels] = useState(['N5']); // N5 selected by default

    const handleJLPTLevelToggle = (level) => {
        setJlptLevels(prev =>
            prev.includes(level)
                ? prev.filter(l => l !== level)
                : [...prev, level]
        );
    };

    const { data: jlptKanji, isLoading: isJLPTLoading } = useKanjiByJLPT(
        selectedType === 'JLPT Level' ? jlptLevels : []
    );


    // Kanji Status Section
    const [selectedStatuses, setSelectedStatuses] = useState(['Awakened']);
    const [kanjiStatusList, setKanjiStatusList] = useState([]);

    const kanjiStatusOptions = [
        'Awakened',
        'Explorer',
        'Mastery',
        'Ascension',
        'Slayed'
    ];

    const { data: statusKanji, isLoading: isStatusKanjiLoading } = useKanjiByStatus(
        user?.uid,
        selectedType === 'Kanji Status' ? selectedStatuses : []
    );



    //Validate Quiz Setup

    const [quizLoading, setQuizLoading] = useState(false);
    const setupCustomQuiz = createCallableFunction('setupCustomQuiz');

    // Add this validation function
    const isKanji = (char) => {
        return /^[\u4E00-\u9FAF]$/.test(char);
    };

    // Add this handler for starting the quiz
    const handleStartQuiz = async () => {
        let kanjiList = [];

        if (selectedType === 'Critical Condition Kanji') {
            kanjiList = criticalKanjiList;
        } else if (selectedType === 'OniKanji Level') {
            kanjiList = levelKanji?.map(k => k.kanji) || [];
        } else if (selectedType === 'JLPT Level') {
            kanjiList = jlptKanji?.map(k => k.kanji) || [];
        } else if (selectedType === 'Kanji Status') {
            kanjiList = statusKanji?.map(k => k.kanji) || [];
        }

        // Validate kanji list
        if (!kanjiList.length) {
            toast.error('Please select at least one kanji to study');
            return;
        }

        try {
            setQuizLoading(true);
            
            // Determine question style based on excludedAnswerTypes
            const questionStyle = excludedAnswerTypes.includes('Multiple Choice') 
                ? 'fillInTheBlank' 
                : excludedAnswerTypes.includes('Fill in the Blank')
                    ? 'multipleChoice'
                    : 'both';

            const result = await setupCustomQuiz({
                kanjiList,
                excludedQuestionTypes,
                questionStyle
            });

            if (result.data.preparedQuizList.length > 0) {
                navigate('/extra-study/custom-quiz', { 
                    state: { 
                        quizData: result.data.preparedQuizList,
                        showFlashcard
                    } 
                });
            } else {
                toast.error('No questions available for selected kanji');
            }
        } catch (error) {
            console.error('Error setting up quiz:', error);
            toast.error(error.message || 'Failed to setup quiz');
        } finally {
            setQuizLoading(false);
        }
    };

    return (
        <div className="font-noto-sans-jp">
            <ToastContainer />
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />
            <div className="bg-mainBackgroundColor min-h-screen flex flex-col items-center p-4">


                {/* Selection Section */}
                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                    <h2 className="text-2xl sm:text-3xl font-bold text-black mb-4 sm:mb-6 text-center sm:text-left">Extra Study</h2>

                    <div className="flex flex-col sm:flex-row sm:flex-wrap gap-2 mb-6">
                        {['Critical Condition Kanji', 'OniKanji Level', 'JLPT Level', 'Kanji Status', 'Custom List'].map((option) => (
                            <button
                                key={option}
                                onClick={() => {
                                    if (option === 'Custom List') {
                                        toast.info('Custom Lists Coming Soon!', {
                                            position: "top-center",
                                            autoClose: 3000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                        });
                                    } else {
                                        setSelectedType(option);
                                    }
                                }}
                                className={`flex-1 p-2 sm:p-3 border border-darkBlueColor rounded-lg mb-2 sm:mb-0 sm:mx-1 cursor-pointer transition-colors duration-300 text-center ${
                                    selectedType === option
                                        ? 'bg-darkBlueColor text-white'
                                        : 'bg-white text-darkBlueColor hover:bg-darkBlueColor hover:text-white'
                                }`}
                            >
                                {option}
                            </button>
                        ))}
                    </div>

                    <h3 className="text-xl font-bold text-black mb-3">Question Types</h3>
                    <div className="flex flex-wrap gap-2 mb-4">
                        {[
                            {display: 'Primary Kanji Meanings', value: 'primaryMeaning'},
                            {display: 'Kunyomi', value: 'Kunyomi'},
                            {display: 'Onyomi', value: 'Onyomi'}
                        ].map(({display, value}) => (
                            <button
                                key={value}
                                onClick={() => setExcludedQuestionTypes(prev =>
                                    prev.includes(value)
                                        ? prev.filter(type => type !== value)
                                        : [...prev, value]
                                )}
                                className={`px-3 py-1.5 border border-darkBlueColor rounded-lg text-sm cursor-pointer transition-colors duration-300 text-center ${
                                    excludedQuestionTypes.includes(value)
                                        ? 'bg-white text-darkBlueColor'
                                        : 'bg-darkBlueColor text-white'
                                }`}
                            >
                                {display}
                            </button>
                        ))}
                    </div>

                    <h3 className="text-xl font-bold text-black mb-3">Question Style</h3>
                    <div className="flex flex-wrap gap-2 mb-4">
                        {['Fill in the Blank', 'Multiple Choice'].map((option) => (
                            <button
                                key={option}
                                onClick={() => setExcludedAnswerTypes(prev =>
                                    prev.includes(option)
                                        ? prev.filter(type => type !== option)
                                        : [...prev, option]
                                )}
                                className={`px-3 py-1.5 border border-darkBlueColor rounded-lg text-sm cursor-pointer transition-colors duration-300 text-center ${
                                    excludedAnswerTypes.includes(option)
                                        ? 'bg-white text-darkBlueColor'
                                        : 'bg-darkBlueColor text-white'
                                }`}
                            >
                                {option}
                            </button>
                        ))}
                    </div>

                    <h3 className="text-xl font-bold text-black mb-3">Display Flashcards After Answer</h3>
                    <div className="flex gap-2 mb-4">
                        {['Yes', 'No'].map((option) => (
                            <button
                                key={option}
                                onClick={() => setShowFlashcard(option === 'Yes')}
                                className={`px-6 py-2 border border-darkBlueColor rounded-lg text-sm cursor-pointer transition-colors duration-300 text-center ${
                                    (option === 'Yes' && showFlashcard) || (option === 'No' && !showFlashcard)
                                        ? 'bg-darkBlueColor text-white'
                                        : 'bg-white text-darkBlueColor'
                                }`}
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                    {selectedType === 'OniKanji Level' && (
                        <>
                            <h3 className="text-xl font-bold text-black mb-3">Select Levels to Include</h3>
                            <div className="flex flex-wrap gap-2 mb-4">
                                {[...Array(userLevel || 0)].map((_, index) => {
                                    const level = index + 1;
                                    return (
                                        <button
                                            key={level}
                                            onClick={() => handleLevelToggle(level)}
                                            className={`px-3 py-1.5 border border-darkBlueColor rounded-lg text-sm cursor-pointer transition-colors duration-300 text-center ${
                                                excludedLevels.includes(level)
                                                    ? 'bg-white text-darkBlueColor'
                                                    : 'bg-darkBlueColor text-white'
                                            }`}
                                        >
                                            Level {level}
                                        </button>
                                    );
                                })}
                            </div>
                        </>
                    )}

                    {selectedType === 'JLPT Level' && (
                        <>
                            <h3 className="text-xl font-bold text-black mb-3">Select JLPT Levels to Include</h3>
                            <div className="flex flex-wrap gap-2 mb-4">
                                {['N5', 'N4', 'N3', 'N2', 'N1'].map((level) => (
                                    <button
                                        key={level}
                                        onClick={() => handleJLPTLevelToggle(level)}
                                        className={`px-3 py-1.5 border border-darkBlueColor rounded-lg text-sm cursor-pointer transition-colors duration-300 text-center ${jlptLevels.includes(level)
                                                ? 'bg-darkBlueColor text-white'
                                                : 'bg-white text-darkBlueColor'
                                            }`}
                                    >
                                        {level}
                                    </button>
                                ))}
                            </div>
                        </>
                    )}

                    {selectedType === 'Kanji Status' && (
                        <>
                            <h3 className="text-xl font-bold text-black mb-3">Select Kanji Status</h3>
                            <div className="flex flex-wrap gap-2 mb-4">
                                {kanjiStatusOptions.map((status) => (
                                    <button
                                        key={status}
                                        onClick={() => setSelectedStatuses(prev =>
                                            prev.includes(status)
                                                ? prev.filter(s => s !== status)
                                                : [...prev, status]
                                        )}
                                        className={`px-3 py-1.5 border border-darkBlueColor rounded-lg text-sm cursor-pointer transition-colors duration-300 text-center ${
                                            selectedStatuses.includes(status)
                                                ? 'bg-darkBlueColor text-white'
                                                : 'bg-white text-darkBlueColor'
                                        }`}
                                    >
                                        {status}
                                    </button>
                                ))}
                            </div>
                        </>
                    )}
                </div>

                {/* Preview Section */}
                <div className="max-w-screen-lg w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 mb-6">
                    <h3 className="text-xl sm:text-2xl font-bold text-black mb-4">Quiz Preview:</h3>
                    <div className="min-h-[200px]">
                        {selectedType === 'JLPT Level' ? (
                            <JLPTKanjiDisplay 
                                levelKanji={jlptKanji}
                                isKanjiLoading={isJLPTLoading}
                                includedLevels={jlptLevels}
                            />
                        ) : selectedType === 'Critical Condition Kanji' ? (
                            <CriticalKanjiDisplay 
                                criticalKanji={criticalKanji}
                                isLoading={isCriticalKanjiLoading}
                                error={criticalKanjiError}
                            />
                        ) : selectedType === 'OniKanji Level' ? (
                            <OniKanjiLevelDisplay 
                                levelKanji={levelKanji}
                                isKanjiLoading={isKanjiLoading}
                                includedLevels={includedLevels}
                            />
                        ) : selectedType === 'Kanji Status' && (
                            <KanjiStatusDisplay 
                                statusKanji={statusKanji}
                                isStatusKanjiLoading={isStatusKanjiLoading}
                                selectedStatuses={selectedStatuses}
                            />
                        )}
                    </div>

                    <div className="flex justify-start mt-6 mb-4">
                        <button
                            className="bg-darkOrangeColor text-white px-8 py-3 rounded-lg hover:bg-darkOrangeColorButtonHover transition-colors duration-300 text-lg flex items-center justify-center"
                            onClick={handleStartQuiz}
                            disabled={quizLoading}
                        >
                            {quizLoading ? (
                                <ClipLoader color="#ffffff" size={20} />
                            ) : (
                                'Start Quiz'
                            )}
                        </button>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default ExtraStudySelectionScreen;
