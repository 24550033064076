import React from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const OniKanjiLevelDisplay = ({ levelKanji, isKanjiLoading, includedLevels }) => {
  if (isKanjiLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ClipLoader color="#014156" className="mr-2" />
      </div>
    );
  }

  if (!levelKanji || levelKanji.length === 0) {
    return <p className="text-gray-600">No kanji found for selected levels</p>;
  }

  return (
    <div className="max-w-screen-lg w-full mx-auto">
      <div className="text-lg mb-4">
        Selected {levelKanji.length} kanji from levels: {includedLevels.join(', ')}
      </div>
      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-2 mt-6">
        {levelKanji.slice(0, 20).map(kanji => (
          <Link
            to={`/kanji/${encodeURIComponent(kanji.kanji)}`}
            key={kanji.id}
            className="flex flex-col items-center p-2 bg-white border rounded-lg shadow hover:bg-darkBlueColor hover:text-white transition duration-300"
          >
            <div className="text-3xl font-bold mb-1">
              {kanji.kanji}
            </div>
            <div className="text-sm text-ellipsis overflow-hidden w-full text-center">
              Level {kanji.level}
            </div>
          </Link>
        ))}
        {levelKanji.length > 20 && (
          <div className="flex flex-col items-center p-2 bg-white border rounded-lg shadow">
            <div className="text-lg text-gray-600">+{levelKanji.length - 20} more</div>
          </div>
        )}
      </div>
    </div>
  );
};