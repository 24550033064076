import { useQuery } from '@tanstack/react-query';
import { getFirestore, collection, query, where,getDocs } from 'firebase/firestore';



export const useKanjiByJLPT = (selectedLevels) => {
  return useQuery({
    queryKey: ['jlptKanji', selectedLevels],
    queryFn: async () => {
      if (!selectedLevels.length) return [];
      const db = getFirestore();
      
      const kanjiRef = collection(db, 'allKanji');
      const q = query(kanjiRef, where('JLPT', 'in', selectedLevels));
      const querySnapshot = await getDocs(q);
      
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    },
    enabled: selectedLevels.length > 0
  });
};
