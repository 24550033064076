// InputArea.js
import React from 'react';

const InputArea = ({
  onScreenQuestion,
  userInput,
  handleInputChange,
  handleQuestionSubmission,
  inputRef,
  isCorrect,
  multipleChoiceOptions,
  handleMultipleChoiceSelection,
  multipleChoiceAnswerCheck,
  showingAnswer,
  correctAnswer,
  isSkipping
}) => {
  return (
    <div
      className={`border-4 p-2 sm:p-4 md:p-6 lg:p-8 mx-auto rounded-b-lg w-full transition-colors duration-500 ${isCorrect ? 'bg-white border-green-300' : isCorrect === false ? 'bg-white border-red-300' : 'bg-white border-lightBlueBackground'
        }`}
    >
      {onScreenQuestion?.inputType === 'multipleChoice' ? (
        <div className="grid grid-cols-2 gap-2 sm:gap-4 md:gap-6 lg:gap-8">
          {showingAnswer ? (
            <div className="col-span-2 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">
              Correct Answer: {correctAnswer.join(', ')}
            </div>
          ) : (
            multipleChoiceOptions.map((option, index) => (
              <button
                key={index}
                onClick={() => handleMultipleChoiceSelection(option)}
                className={`p-1 sm:p-2 md:p-3 lg:p-4 text-base sm:text-lg md:text-xl lg:text-2xl font-semibold rounded-lg text-center focus:outline-none flex items-center justify-center ${!multipleChoiceAnswerCheck
                    ? 'bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover text-white'
                    : isCorrect
                      ? 'bg-green-200 text-darkBlueColor'
                      : 'bg-red-200 text-darkBlueColor'
                  }`}
              >
                <div className="flex items-center justify-center w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 mr-1 sm:mr-2 md:mr-3 lg:mr-4 bg-white text-black rounded-lg text-xs md:text-sm lg:text-base">
                  {index + 1}
                </div>
                {option}
              </button>
            ))
          )}
        </div>
      ) : showingAnswer ? (
        <div className="flex flex-col items-center">
          <div className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-4">
            Correct Answer: {correctAnswer.join(', ')}
          </div>
        </div>
      ) : (
        <div className="relative">
          <input
            ref={inputRef}
            type="text"
            value={userInput}
            onChange={handleInputChange}
            onKeyDown={handleQuestionSubmission}
            className={`w-full p-3 sm:p-5 md:p-5 lg:p-5 text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold rounded-lg text-center placeholder-center focus:outline-none h-14 sm:h-16 md:h-18 lg:h-20 ${isCorrect ? 'bg-green-50 text-black' : isCorrect === false ? 'bg-red-50 text-black' : 'bg-white text-black'
              }`}
            placeholder={
              onScreenQuestion?.inputType === 'english'
                ? 'Enter Your Answer'
                : onScreenQuestion?.type.includes('kunyomi')
                  ? 'くんよみ'
                  : onScreenQuestion?.type.includes('onyomi')
                    ? 'オンヨミ'
                    : '答え'
            }
            style={{ textAlign: 'center' }}
          />
          <button
            onClick={() => handleQuestionSubmission({ key: 'Enter' })}
            className={`absolute right-2 top-1/2 transform -translate-y-1/2 text-white font-bold py-2 sm:py-2.5 md:py-3 lg:py-3.5 px-3 sm:px-3.5 md:px-4 lg:px-4.5 rounded-lg transition duration-300 ease-in-out flex items-center justify-center ${isCorrect ? 'bg-green-500 hover:bg-green-600' : isCorrect === false ? 'bg-red-500 hover:bg-red-600' : isSkipping ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover'
              }`}
            title="Press Enter or click here to submit"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8 lg:h-9 lg:w-9"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default InputArea;
