import React, { useEffect } from 'react';
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';
import { useUser } from '../components/utils/UserContext';

import MainQuizEngine from '../quizEngine/mainQuizEngine'; 

function ExtraStudyQuizDisplay() {
  const { user, loading: userLoading, subscriptionStatus } = useUser();

  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const location = useLocation();
  const quizData = location.state?.quizData || [];
  const showFlashcard = location.state?.showFlashcard ?? true;

  useEffect(() => {
    document.title = "OniKanji - Extra Study Quiz";
    if (!userLoading) {
      if (!user) {
        navigate('/login');
      } else if (navigationType !== 'PUSH') {
        navigate('/home');
      }
    }
  }, [user, userLoading, navigate, navigationType]);

  useEffect(() => {
    if (!quizData || quizData.length === 0) {
      navigate('/extra-study');
    }
  }, [quizData, navigate]);

  return (
        <MainQuizEngine
          quizData={quizData}
          saveProgressEnabled={false} 
          showFlashcardEnabled={showFlashcard}
        />
  );
}

export default ExtraStudyQuizDisplay;
