import { BiVolumeFull } from "react-icons/bi";
import { useState } from 'react';

import { generateSSMLText } from './generateSSML';

export const useAudioPlayback = () => {
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);

    const handleTextToSpeech = async (sentence, furiganaSampleSentence, vocabWord) => {
        if (isAudioPlaying) {
            console.log('Audio is already playing. Please wait.');
            return;
        }

        try {
            setIsAudioPlaying(true);

            // Generate SSML text
            const ssmlText = generateSSMLText(sentence, furiganaSampleSentence, vocabWord);

            const response = await fetch('https://generateaudiotts-bp4luzrbha-uc.a.run.app', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text: ssmlText }),
            });

            if (!response.ok) {
                let errorMessage = 'Failed to fetch audio';
                try {
                    const errorData = await response.json();
                    errorMessage = errorData.error || errorMessage;
                } catch (parseError) {
                    // Ignore JSON parsing errors
                }
                throw new Error(errorMessage);
            }

            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);

            const audio = new Audio(audioUrl);
            // Removed playback rate adjustment as it might not be necessary for Google TTS
            audio.play();

            audio.onended = () => {
                URL.revokeObjectURL(audioUrl);
                setIsAudioPlaying(false);
            };
        } catch (error) {
            console.error('Error fetching audio:', error.message);
            setIsAudioPlaying(false);
        }
    };

    const PulsingVolumeIcon = ({ isPlaying }) => {
        return (
            <span className={`inline-block ${isPlaying ? 'animate-intensePulse' : ''}`}>
                <BiVolumeFull />
            </span>
        );
    };

    return { handleTextToSpeech, isAudioPlaying, PulsingVolumeIcon };
};
