import React from 'react';

import logoImage from '../images/landingPageAssets/onikanji-logo.png';
import usaFlagImage from '../images/landingPageAssets/onikanji-usa-flag.png';
import japanFlagImage from '../images/landingPageAssets/onikanji-japan-flag.png';
import { FaBluesky } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";


const Footer = () => {
    return (
        <footer className="bg-white text-black py-12 px-4 md:px-8 relative mt-auto">

        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-evenly">
            <div className="mb-8 md:mb-0 text-center md:text-left">
                <img 
                    src={logoImage} 
                    alt="Onikanji Logo" 
                    className="h-12 md:h-16 mb-6 mx-auto md:mx-0 cursor-pointer"
                    onClick={() => window.location.href = '/'} 
                />
                <div className="mb-6">
                    <p className="text-lg mb-2">
                        Made with love from <img src={usaFlagImage} alt="USA Flag" className="inline w-5 h-5" /> & <img src={japanFlagImage} alt="Japan Flag" className="inline w-5 h-5" />
                    </p>
                    <p className="text-sm text-gray-600">
                        © {new Date().getFullYear()} OniKanji Education LLC. All rights reserved.
                    </p>
                </div>
                <div className="flex justify-center md:justify-start space-x-4">
                    <a href="https://bsky.app/profile/onikanji.bsky.social" className="text-gray-600 hover:text-logoColor transition-colors duration-300">
                        <FaBluesky className="w-6 h-6" />
                    </a>
                    <a href="https://www.tiktok.com/@onikanji6" className="text-gray-600 hover:text-logoColor transition-colors duration-300">
                        <FaTiktok className="w-6 h-6" />
                    </a>
                    <a href="https://discord.gg/7TEG9cqZTP" className="text-gray-600 hover:text-logoColor transition-colors duration-300">
                        <FaDiscord className="w-7 h-7" />
                    </a>
                </div>
            </div>
            <div className="w-full md:w-1/2 mb-8 md:mb-0 flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-16 text-center md:text-left">
                <div>
                    <h4 className="font-bold text-lg mb-4">Company</h4>
                    <ul className="space-y-2">
                        <li><a href="https://discord.gg/7TEG9cqZTP" className="text-gray-600 hover:text-logoColor transition-colors duration-300">Discord</a></li>
                        <li><a href="/pricing" className="text-gray-600 hover:text-logoColor transition-colors duration-300">Pricing</a></li>
                        <li><a href="https://knowledge.onikanji.com/" className="text-gray-600 hover:text-logoColor transition-colors duration-300">Knowledge Center</a></li>
                        <li><a href="https://knowledge.onikanji.com/why-onikanji/" className="text-gray-600 hover:text-logoColor transition-colors duration-300">Our Method</a></li>
                        <li><a href="https://knowledge.onikanji.com/" className="text-gray-600 hover:text-logoColor transition-colors duration-300">FAQs</a></li>
                    </ul>
                </div>
                <div>
                    <h4 className="font-bold text-lg mb-4">Connect</h4>
                    <ul className="space-y-2">
                        <li><a href="https://knowledge.onikanji.com/" className="text-gray-600 hover:text-logoColor transition-colors duration-300">About Us</a></li>

                        <li><a href="https://knowledge.onikanji.com/company/contact-us" className="text-gray-600 hover:text-logoColor transition-colors duration-300">Join Our Team</a></li>
                        <li><a href="https://knowledge.onikanji.com/company/contact-us" className="text-gray-600 hover:text-logoColor transition-colors duration-300">Contact Us</a></li>
                        <li><a href="https://knowledge.onikanji.com/company/privacy-policy" className="text-gray-600 hover:text-logoColor transition-colors duration-300">Privacy Policy</a></li>
                        <li><a href="https://knowledge.onikanji.com/company/terms-of-service-and-use" className="text-gray-600 hover:text-logoColor transition-colors duration-300">Terms of Service</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-center text-gray-500 text-sm">
                By using this site, you agree to our <a href="https://knowledge.onikanji.com/company/terms-of-service-and-use" className="text-logoColor hover:underline">Terms of Service</a> and <a href="https://knowledge.onikanji.com/company/privacy-policy" className="text-logoColor hover:underline">Privacy Policy</a>.
            </p>
        </div>
    </footer>
    );
};

export default Footer;