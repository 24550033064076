// QuestionDisplay.js
import React from 'react';

const QuestionDisplay = ({
  onScreenQuestion,
  renderOnScreenValue,
  toggleFurigana,
  setShowFurigana,
}) => {
  return (
    <div className="flex items-center justify-center h-48 sm:h-64 md:h-80 lg:h-96 mb-4 sm:mb-6 md:mb-8 lg:mb-10">
      <div
        className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-medium"
        onClick={toggleFurigana}
        onMouseEnter={() => setShowFurigana(true)}
        onMouseLeave={() => setShowFurigana(false)}
      >
        {renderOnScreenValue(
          onScreenQuestion?.onScreenValue,
          onScreenQuestion?.questionAssociatedKanji,
          onScreenQuestion?.inputType,
          onScreenQuestion?.furiganaMap
        )}
      </div>
    </div>
  );
};

export default QuestionDisplay;
