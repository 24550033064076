import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';

import { createCallableFunction } from '../config/firebase';

//import toolTip
import Tooltip from '../quizEngine/toolTip';

//import quizStats
import StatsDisplay from '../quizEngine/quizStats';

//import questionDisplay
import QuestionDisplay from '../quizEngine/showRenderedQuestion';
//import renderOnScreenValue
import { renderOnScreenValue } from '../quizEngine/renderOnScreenValue';

//import userInputArea
import InputArea from '../quizEngine/userInputArea';

//import modules
import { convertRomajiToHiragana } from '../components/utils/languageUtils';
import { fetchAllKanjiData } from '../components/utils/fetchKanjiData';
import { checkAnswer } from '../components/utils/answerChecker';
import { renderSentenceWithFurigana } from '../components/utils/furiganaSentenceBuilder';

//import images
import hiraganaKeyboardImage from '../images/hiragana-keyboard-mapping.png';

//reviews completed
import oniKanjiReviewsCompletedImage from '../images/appHomeAssets/onikanji-review-button-graphic.webp';
import { ClipLoader } from 'react-spinners';

//import sounds
import correctSound from '../images/sounds/right_answer_onikanji.mp3';
import incorrectSound from '../images/sounds/wrong_answer_onikanji.wav';

//import toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import icons
import { MdKeyboardTab } from "react-icons/md";


function MainQuizEngine({
    quizData,
    saveProgressEnabled = false,
    showFlashcardEnabled = false

}) {

    const { user, loading: userLoading, subscriptionStatus } = useUser();

    //get the location and navigation type
    const navigate = useNavigate();
    const navigationType = useNavigationType();
    const location = useLocation();


    const [quizList, setQuizList] = useState([]);
    const [onScreenQuestion, setOnScreenQuestion] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState([]);

    const [saveProgressCount, setSaveProgressCount] = useState(0);

    const [loading, setLoading] = useState(true);
    const [userInput, setUserInput] = useState("");

    const [isCorrect, setIsCorrect] = useState(null);
    const [quizFinished, setQuizFinished] = useState(false);
    const [quizMode, setQuizMode] = useState('english');
    const [multipleChoiceOptions, setMultipleChoiceOptions] = useState([]);
    const [multipleChoiceAnswerCheck, setMultipleChoiceAnswerCheck] = useState(false);

    const [startTime, setStartTime] = useState(null);
    const inputRef = useRef(null);

    const [isSkipping, setIsSkipping] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [showContextHint, setShowContextHint] = useState(false);
    const [showingAnswer, setShowingAnswer] = useState(false);
    const [useKatakana, setUseKatakana] = useState(false);

    const correctAudio = new Audio(correctSound);
    const incorrectAudio = new Audio(incorrectSound);


    // Define the saveUserReviews callable function
    const saveUserReviews = createCallableFunction('saveUserReviewsV3');

    const saveProgress = (kanji, type, correctness, timeToAnswer) => {
        if (saveProgressEnabled) {
            saveUserReviews([kanji, type, correctness, timeToAnswer])
                .then((result) => {
                    setSaveProgressCount(prevCount => {
                        const newCount = prevCount + 1;
                        if (newCount >= 500) {
                            toast.info("How about a break? Too many requests in one session", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored"
                            });
                            setTimeout(() => {
                                navigate('/home', { state: { previousPath: '/reviews/' } });
                            }, 5000);
                        }
                        return newCount;
                    });
                })
                .catch((error) => {
                    console.error('Error saving user review:', error);
                });
        } else {
            // console.log('Progress not saved - Extra Study mode');
        }
    };


    useEffect(() => {
        document.title = "OniKanji - Extra Study";
        if (!userLoading) {
            if (!user) {
                navigate('/login');
            } else if (navigationType !== 'PUSH') {
                navigate('/home');
            } else if (quizData && quizData.length > 0) {


                // const shuffledQuizList = shuffleArray(reviews);
                const shuffledQuizList = quizData;
                setQuizList(shuffledQuizList);
                // console.log(shuffledQuizList);


                if (shuffledQuizList.length > 0) {
                    nextQuestionHandler(shuffledQuizList);
                    setStartTime(Date.now());  // Set the start time when the first question is displayed
                }
                setLoading(false);
            }
        }
    }, [user, userLoading, navigate, quizData, navigationType]);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const nextQuestionHandler = (updatedQuizList) => {
        if (updatedQuizList.length === 0) {
            setOnScreenQuestion(null);
            setCorrectAnswer([]);
            setQuizFinished(true);

            return;
        }

        const nextQuestion = updatedQuizList[0];
        setOnScreenQuestion(nextQuestion);
        setShowContextHint(false);
        setShowFlashcard(false);
        setFlashcardData(null);

        // Set the correct answer and multiple choice options based on the question type
        setCorrectAnswer(Array.isArray(nextQuestion.correctAnswer) ? nextQuestion.correctAnswer : [nextQuestion.correctAnswer]);

        if (nextQuestion.inputType === 'multipleChoice') {
            const options = new Set(nextQuestion.multipleChoiceOptions); // Use a Set to avoid duplicates
            const correctAnswers = Array.isArray(nextQuestion.correctAnswer) ? nextQuestion.correctAnswer : [nextQuestion.correctAnswer];
            correctAnswers.forEach(answer => options.add(answer)); // Add all correct answers to the Set
            setMultipleChoiceOptions(shuffleArray(Array.from(options))); // Convert Set back to array and shuffle
        } else {
            setMultipleChoiceOptions([]);
        }

        // Set the quiz mode
        setQuizMode(nextQuestion.inputType);

        setUserInput('');
        setIsCorrect(null);
        setMultipleChoiceAnswerCheck(false);  // Reset the answer check state

        setStartTime(Date.now());  // Reset the start time for each new question

        // Focus the input element for non-multiple choice questions
        if (nextQuestion.inputType !== 'multipleChoice') {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 0);
        }
    };

    const handleQuestionSubmission = (event) => {
        if (event.key === 'Enter') {
            if (isCorrect !== null) {
                // Proceed to the next question
                setShowFlashcard(false); // Reset flashcard visibility
                setFlashcardData(null);  // Reset flashcard data
                
                setQuizList((prevQuizList) => {
                    const updatedQuizList = prevQuizList.slice(1);
                    nextQuestionHandler(updatedQuizList);
                    return updatedQuizList;
                });
            } else {
                const isCorrectAnswer = checkAnswer(onScreenQuestion?.inputType, correctAnswer, userInput);
                const timeToAnswer = (Date.now() - startTime) / 1000;

                if (isCorrectAnswer) {
                    setIsCorrect(true);
                    playSound(true);
                    saveProgress(onScreenQuestion.questionAssociatedKanji, onScreenQuestion.type, "correct", timeToAnswer);
                    updateStats('correct');
                } else {
                    setIsCorrect(false);
                    playSound(false);
                    saveProgress(onScreenQuestion.questionAssociatedKanji, onScreenQuestion.type, "incorrect", timeToAnswer);
                    updateStats('wrong');
                    setQuizList((prevQuizList) => {
                        const updatedQuizList = [...prevQuizList, onScreenQuestion];
                        return updatedQuizList;
                    });
                }

                // Automatically show the flashcard if enabled and not showing answer
                if (showFlashcardEnabled && !showingAnswer) {
                    handleShowFlashcard();
                }
            }
        }
    };


    const handleMultipleChoiceSelection = (selectedOption) => {
        if (!multipleChoiceAnswerCheck) {
            const isCorrectAnswer = correctAnswer.includes(selectedOption);
            setIsCorrect(isCorrectAnswer);
            setMultipleChoiceAnswerCheck(true);
            const timeToAnswer = (Date.now() - startTime) / 1000;

            if (isCorrectAnswer) {
                saveProgress(onScreenQuestion.questionAssociatedKanji, onScreenQuestion.type, "correct", timeToAnswer);
                updateStats('correct');
                playSound(true);
            } else {
                saveProgress(onScreenQuestion.questionAssociatedKanji, onScreenQuestion.type, "incorrect", timeToAnswer);
                updateStats('wrong');
                playSound(false);
                setQuizList(prevList => [...prevList, onScreenQuestion]);
            }

            // Automatically show the flashcard if enabled and not showing answer
            if (showFlashcardEnabled && !showingAnswer) {
                handleShowFlashcard();
            }
        } else {
            setQuizList(prevList => {
                const updatedQuizList = prevList.slice(1);
                nextQuestionHandler(updatedQuizList);
                return updatedQuizList;
            });
            setShowFlashcard(false); // Reset flashcard visibility
        }
    };


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (onScreenQuestion?.inputType === 'multipleChoice') {
                const key = event.key;
                if (['1', '2', '3', '4'].includes(key)) {
                    const index = parseInt(key) - 1;
                    if (index < multipleChoiceOptions.length) {
                        handleMultipleChoiceSelection(multipleChoiceOptions[index]);
                    }
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [onScreenQuestion, multipleChoiceOptions, handleMultipleChoiceSelection]);

    const handleInputChange = (event) => {
        let input = event.target.value.toLowerCase();
        if (quizMode !== 'english') {
            input = convertRomajiToHiragana(input, useKatakana);
        }
        setUserInput(input);
    };

    //Begin Section to handle the logic for the four buttons

    const [kanjiContextData, setKanjiContextData] = useState(null);
    const handleShowAnswer = async () => {
        // Hide the flashcard if it's showing
        if (showFlashcard) {
            setShowFlashcard(false);
            setFlashcardData(null);
        }

        if (!showingAnswer) {
            // First click: Show the answer
            setShowContextHint(false);
            setIsCorrect(false);
            playSound(false);
            updateStats('wrong');
            setShowingAnswer(true);
            const timeToAnswer = (Date.now() - startTime) / 1000;
            saveProgress(onScreenQuestion.questionAssociatedKanji, onScreenQuestion.type, "incorrect", timeToAnswer);

            // Fetch kanji context data
            try {
                // Fetch all necessary Kanji data
                const data = await fetchAllKanjiData(onScreenQuestion.questionAssociatedKanji);
                let contextData = null;

                // Define a mapping from suffix to array index
                const suffixToIndexMap = {
                    'One': 0,
                    'Two': 1,
                    'Three': 2,
                    'Four': 3,
                    'Five': 4,
                    'Six': 5
                };

                // Regular expression to detect and extract the suffix
                const suffixRegex = /(One|Two|Three|Four)$/;
                const suffixMatch = onScreenQuestion.type.match(suffixRegex);

                // Determine the index based on the suffix; default to 0 if no suffix
                const index = suffixMatch ? suffixToIndexMap[suffixMatch[1]] : 0;

                // Function to extract vocab data based on type and index
                const getVocabData = (vocabArray, idx) => {
                    const vocab = vocabArray?.[idx] || {};
                    return {
                        sampleSentence: vocab.sampleSentence || 'N/A',
                        sampleSentenceMeaning: vocab.sampleSentenceMeaning || 'N/A',
                        vocabWord: vocab.word || 'N/A',
                        vocabWordReading: vocab.reading || 'N/A',
                        vocabWordMeaning: vocab.meaning || 'N/A',
                        // Add furigana data
                        furiganaSentence: vocab.sampleSentence ? 
                            renderSentenceWithFurigana(
                                vocab.sampleSentence.replace(/[（(].*?[)）]/g, ''),
                                vocab.furiganaSampleSentence,
                                vocab.word,
                                true
                            ) : null
                    };
                };

                // Determine the base type by removing the suffix if present
                const baseType = suffixMatch ? onScreenQuestion.type.replace(suffixRegex, '') : onScreenQuestion.type;

                // Handle different base types
                if (baseType.includes('kunyomi')) {
                    contextData = getVocabData(data.kunyomi?.vocabWords, index);
                } else if (baseType.includes('onyomi')) {
                    contextData = getVocabData(data.onyomi?.vocabWords, index);
                } else if (baseType === 'primaryMeaning') {
                    contextData = {
                        primaryMeaning: data.primaryMeaning || 'N/A',
                        secondaryMeaning: data.secondaryMeaning || 'N/A',
                    };
                }


                setKanjiContextData(contextData);
            } catch (error) {
                console.error('Error fetching kanji context data:', error);
            }
        } else {
            // Second click: Move to the next question
            setShowingAnswer(false);
            setKanjiContextData(null);
            setQuizList((prevQuizList) => {
                const updatedQuizList = prevQuizList.slice(1);
                nextQuestionHandler(updatedQuizList);
                return updatedQuizList;
            });
        }
    };

    const handleSkip = () => {
        setIsSkipping(true);
        updateStats('skip');
        setShowingAnswer(false);
        setShowContextHint(false);
        setQuizList(prevList => {
            const updatedQuizList = [...prevList.slice(1), onScreenQuestion];
            setTimeout(() => {
                nextQuestionHandler(updatedQuizList);
                setIsSkipping(false);
            }, 700);
            return updatedQuizList;
        });
    };

    const [dataForContextHint, setDataForContextHint] = useState(false);
    const handleShowContextHint = async () => {
        if (!showContextHint) {
            setShowContextHint(true);
            try {
                const data = await fetchAllKanjiData(onScreenQuestion.questionAssociatedKanji);
                let contextData = null;

                if (onScreenQuestion.type.includes('kunyomi') ||
                    onScreenQuestion.type.includes('onyomi') ||
                    onScreenQuestion.type.includes('multipleChoiceReading')) {

                    let vocabData;
                    if (onScreenQuestion.type.includes('onyomi') || onScreenQuestion.type.includes('multipleChoiceReadingOnyomi')) {
                        const onyomiNumber = onScreenQuestion.type.match(/(?:One|Two|Three|Four)$/);
                        const index = onyomiNumber ? ['One', 'Two', 'Three', 'Four'].indexOf(onyomiNumber[0]) : 0;
                        vocabData = data.onyomi?.vocabWords[index] || data.onyomi?.vocabWords[0];
                    } else {
                        const kunyomiNumber = onScreenQuestion.type.match(/(?:One|Two|Three|Four)$/);
                        const index = kunyomiNumber ? ['One', 'Two', 'Three', 'Four'].indexOf(kunyomiNumber[0]) : 0;
                        vocabData = data.kunyomi?.vocabWords[index] || data.kunyomi?.vocabWords[0];
                    }

                    contextData = {
                        sampleSentence: vocabData?.sampleSentence ? vocabData.sampleSentence.replace(/[（(].*?[)）]/g, '') : 'N/A',
                        sampleSentenceMeaning: vocabData?.sampleSentenceMeaning || 'N/A',
                        vocabWord: vocabData?.word || 'N/A',
                        vocabWordReading: vocabData?.reading || 'N/A',
                        vocabWordMeaning: vocabData?.meaning || 'N/A',
                        // Add furigana data
                        furiganaSentence: vocabData?.sampleSentence ?
                            renderSentenceWithFurigana(
                                vocabData.sampleSentence.replace(/[（(].*?[)）]/g, ''),
                                vocabData.furiganaSampleSentence,
                                vocabData.word,
                                true
                            ) : null
                    };
                } else if (onScreenQuestion.type === 'primaryMeaning') {
                    contextData = {
                        sampleSentence: "No hint available on Primary Meaning. You gotta know this! 頑張ってね!"
                    };
                }
                setDataForContextHint(contextData);
            } catch (error) {
                console.error('Error fetching kanji context data:', error);
            }
        } else {
            setShowContextHint(false);
            setDataForContextHint(null);
        }
    };

    useEffect(() => {
        const handleTabKey = (event) => {
            if (event.key === 'Tab') {
                event.preventDefault();
                if (!showingAnswer && isCorrect !== false) {
                    handleShowContextHint();
                }
            }
        };

        window.addEventListener('keydown', handleTabKey);
        return () => window.removeEventListener('keydown', handleTabKey);
    }, [showingAnswer, isCorrect, handleShowContextHint]);

    //Check if we should show context hint based on the question type and the onScreenValue
    useEffect(() => {
        if (!onScreenQuestion) {
            return;
        }

        // Check if we should show context hint
        const shouldShowHint = (
            onScreenQuestion.type.includes('Vocabulary') &&
            onScreenQuestion.questionAssociatedKanji === onScreenQuestion.onScreenValue
        );

        if (shouldShowHint) {
            handleShowContextHint(); // Simply call your existing function
        }
    }, [onScreenQuestion]);

    const toggleKeyboard = () => {
        setShowKeyboard(!showKeyboard);
    };

    const [showFurigana, setShowFurigana] = useState(false);
    const toggleFurigana = () => {
        setShowFurigana((prev) => !prev);
    };


    //quiz feedback stats
    const [stats, setStats] = useState({
        correct: 0,
        wrong: 0,
        skip: 0,
        accuracy: 0
    });

    const [highlightedStat, setHighlightedStat] = useState(null);

    const updateStats = (type) => {
        setStats(prevStats => {
            const newStats = {
                ...prevStats,
                [type]: prevStats[type] + 1
            };

            const totalAttempts = newStats.correct + newStats.wrong;
            const accuracy = totalAttempts > 0
                ? Math.round((newStats.correct / totalAttempts) * 100)
                : 0;

            return {
                ...newStats,
                accuracy
            };
        });

        setHighlightedStat(type);

        // Reset the highlight after 3 seconds
        setTimeout(() => {
            setHighlightedStat(null);
        }, 2000);
    };

    const [isSoundOn, setIsSoundOn] = useState(true);

    const toggleSound = () => {
        setIsSoundOn(!isSoundOn);
    };

    const playSound = (isCorrect) => {
        if (isSoundOn) {
            if (isCorrect) {
                correctAudio.play();
            } else {
                incorrectAudio.play();
            }
        }
    };
    //Show Flashcard Enabled Section
    const [showFlashcard, setShowFlashcard] = useState(false);
    const [flashcardData, setFlashcardData] = useState(null);
    const handleShowFlashcard = async () => {
        if (!showingAnswer && !showFlashcard) {
            // This should make sure that the context hint is not shown and the answer is not shown
            setShowContextHint(false);
            setShowingAnswer(false);
            setShowFlashcard(true);

            // Fetch kanji context data
            try {
                // Fetch all necessary Kanji data
                const data = await fetchAllKanjiData(onScreenQuestion.questionAssociatedKanji);
                let contextData = null;

                // Define a mapping from suffix to array index
                const suffixToIndexMap = {
                    'One': 0,
                    'Two': 1,
                    'Three': 2,
                    'Four': 3,
                    'Five': 4,
                    'Six': 5
                };

                // Regular expression to detect and extract the suffix
                const suffixRegex = /(One|Two|Three|Four)$/;
                const suffixMatch = onScreenQuestion.type.match(suffixRegex);

                // Determine the index based on the suffix; default to 0 if no suffix
                const index = suffixMatch ? suffixToIndexMap[suffixMatch[1]] : 0;

                // Function to extract vocab data based on type and index
                const getVocabData = (vocabArray, idx) => {
                    const vocab = vocabArray?.[idx] || {};
                    return {
                        sampleSentence: vocab.sampleSentence || 'N/A',
                        sampleSentenceMeaning: vocab.sampleSentenceMeaning || 'N/A',
                        vocabWord: vocab.word || 'N/A',
                        vocabWordReading: vocab.reading || 'N/A',
                        vocabWordMeaning: vocab.meaning || 'N/A',
                        // Add furigana data
                        furiganaSentence: vocab.sampleSentence ? 
                            renderSentenceWithFurigana(
                                vocab.sampleSentence.replace(/[（(].*?[)）]/g, ''),
                                vocab.furiganaSampleSentence,
                                vocab.word,
                                true
                            ) : null
                    };
                };

                // Determine the base type by removing the suffix if present
                const baseType = suffixMatch ? onScreenQuestion.type.replace(suffixRegex, '') : onScreenQuestion.type;

                if (baseType.includes('multipleChoiceReadingOnyomi') || baseType.includes('onyomi')) {
                    contextData = getVocabData(data.onyomi?.vocabWords, index);
                  } else if (baseType.includes('multipleChoiceReadingKunyomi') || baseType.includes('kunyomi')) {
                    contextData = getVocabData(data.kunyomi?.vocabWords, index);
                  } else if (baseType === 'primaryMeaning') {
                    contextData = {
                      primaryMeaning: data.primaryMeaning || 'N/A',
                      secondaryMeaning: data.secondaryMeaning || 'N/A',
                    };
                  }
            
                setFlashcardData(contextData);
            } catch (error) {
                console.error('Error fetching kanji context data:', error);
            }
        }
    };

    const [loadingHome, setLoadingHome] = useState(false);
    const handleReturnHome = () => {
        setLoadingHome(true);
        setTimeout(() => {
            navigate('/home', { state: { previousPath: '/extra-study/' } });
        }, 3000);
    };

    return (
        <div className="bg-mainBackgroundColor min-h-screen flex flex-col font-noto-sans-jp">
            <Navbar user={user} currentPath={location.pathname} subscriptionStatus={subscriptionStatus} />
            <div className="flex-grow p-4 sm:p-6 md:p-8 lg:p-10">
                <div className={`w-full sm:max-w-full md:max-w-4xl lg:max-w-6xl mx-auto rounded-lg shadow-blueBoxShadow p-4 sm:p-6 md:p-8 lg:p-10 transition-colors duration-500 min-h-[50vh] ${isCorrect ? 'bg-green-100' :
                    isCorrect === false ? 'bg-red-100' :
                        isSkipping ? 'bg-yellow-100' :
                            'bg-white'
                    } relative`}>
                    <Tooltip />
                    {loading ? (
                        <div className="text-center space-y-4">
                            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold">Loading...</h1>
                        </div>
                    ) : (
                        <div className="text-center relative">
                            <StatsDisplay
                                isSoundOn={isSoundOn}
                                toggleSound={toggleSound}
                                stats={stats}
                                highlightedStat={highlightedStat}
                            />
                            <QuestionDisplay
                                onScreenQuestion={onScreenQuestion}
                                renderOnScreenValue={renderOnScreenValue}
                                toggleFurigana={toggleFurigana}
                                setShowFurigana={setShowFurigana}
                            />
                            {showContextHint && dataForContextHint ? (
                                <div className="flex flex-col space-y-2 p-4 mb-4">
                                    <div className="mt-2 text-left">
                                        <div className="bg-lightBlueBackground p-6 rounded-lg shadow-md space-y-4 w-full">
                                            <div className="space-y-2">
                                                <p className="text-darkBlueColor text-sm">Sample Sentence</p>
                                                <div className="text-2xl font-medium text-lightBlueText">
                                                    {dataForContextHint.furiganaSentence || dataForContextHint.sampleSentence}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : showingAnswer ? (
                                <div className="flex flex-col space-y-2 p-4 mb-4">
                                    {kanjiContextData && (
                                        <div className="mt-2 text-left">
                                            {(onScreenQuestion.type.toLowerCase().includes('kunyomi') || onScreenQuestion.type.toLowerCase().includes('onyomi')) && (
                                                <div className={`bg-${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'orange-100' : 'lightBlueBackground'} p-6 rounded-lg shadow-md space-y-4 w-full`}>
                                                    <h3 className={`text-xl font-bold ${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-800' : 'text-lightBlueText'}`}>
                                                        {onScreenQuestion.type.toLowerCase().includes('kunyomi') ? "Kun'yomi" : "On'yomi"}
                                                    </h3>
                                                    <div className="space-y-2">
                                                        <p className={`${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-600' : 'text-darkBlueColor'} text-sm`}>Vocabulary Word</p>
                                                        <div className="flex flex-col space-y-1">
                                                            <div className={`text-3xl font-medium ${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-900' : 'text-lightBlueText'}`}>
                                                                {kanjiContextData?.vocabWord && kanjiContextData.vocabWord !== 'N/A' ? kanjiContextData.vocabWord.replace(/\/\/.*?\/\//g, '') : ''}
                                                            </div>
                                                            <p className={`${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-700' : 'text-darkBlueColor'} text-xl`}>
                                                                {kanjiContextData?.vocabWord && kanjiContextData.vocabWord !== 'N/A' ? kanjiContextData.vocabWordReading : "This reading has no associated vocabulary or sample sentences."}
                                                            </p>
                                                            <div className={`text-2xl ${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-700' : 'text-darkBlueColor'}`}>
                                                                {kanjiContextData?.vocabWord && kanjiContextData.vocabWord !== 'N/A' ? kanjiContextData.vocabWordMeaning : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {kanjiContextData.sampleSentence && kanjiContextData.vocabWord !== 'N/A' && (
                                                        <div className="space-y-2 mt-4">
                                                            <p className={`${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-600' : 'text-darkBlueColor'} text-sm`}>Sample Sentence</p>
                                                            <div className={`text-2xl font-medium ${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-900' : 'text-lightBlueText'}`}>
                                                                {kanjiContextData.furiganaSentence || kanjiContextData.sampleSentence}
                                                            </div>
                                                            <div className={`${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-700' : 'text-darkBlueColor'} text-xl`}>
                                                                {kanjiContextData.sampleSentenceMeaning}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {onScreenQuestion.type === 'primaryMeaning' && (
                                                <>
                                                    <h3 className="font-bold text-xl text-darkBlueColor mb-2">Definition</h3>
                                                    <div className="text-sm sm:text-base md:text-lg lg:text-xl p-4 rounded-lg bg-lightBlueBackground shadow-md text-left">
                                                        <p className="font-bold text-lightBlueText mb-1">Primary Meaning:</p>
                                                        <p className="text-darkBlueColor">{kanjiContextData.primaryMeaning}</p>
                                                        {kanjiContextData.secondaryMeaning && (
                                                            <>
                                                                <p className="font-bold text-lightBlueText mb-1 mt-4">Secondary Meaning:</p>
                                                                <p className="text-darkBlueColor">{kanjiContextData.secondaryMeaning}</p>
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ) : null}
                            <div className={`text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold p-3 sm:p-4 md:p-5 lg:p-6 rounded-t-lg transition-colors duration-500 ${isCorrect ? 'bg-green-200 text-green-800' :
                                isCorrect === false ? 'bg-red-200 text-red-800' :
                                    isSkipping ? 'bg-yellow-200 text-yellow-800' :
                                        'bg-lightBlueBackground text-lightBlueText'
                                }`}>
                                {onScreenQuestion?.inputType === 'multipleChoice'
                                    ? 'Select Correct Kanji Reading'
                                    : onScreenQuestion?.type === 'primaryMeaning'
                                        ? 'Enter Kanji Meaning'
                                        : onScreenQuestion?.type === 'kunyomiPrimaryReading'
                                            ? 'Enter Kunyomi Reading'
                                            : onScreenQuestion?.type.startsWith('kunyomiVocabularyPrimaryMeaning') || onScreenQuestion?.type.startsWith('onyomiVocabularyPrimaryMeaning')
                                                ? 'Enter the Vocabulary Word Meaning'
                                                : onScreenQuestion?.type.startsWith('kunyomiVocabularyPrimaryReading') || onScreenQuestion?.type.startsWith('onyomiVocabularyPrimaryReading')
                                                    ? 'Enter the Vocabulary Word Reading'
                                                    : 'Enter Onyomi Reading'}
                            </div>
                            <InputArea
                                onScreenQuestion={onScreenQuestion}
                                userInput={userInput}
                                handleInputChange={handleInputChange}
                                handleQuestionSubmission={handleQuestionSubmission}
                                inputRef={inputRef}
                                isCorrect={isCorrect}
                                multipleChoiceOptions={multipleChoiceOptions}
                                handleMultipleChoiceSelection={handleMultipleChoiceSelection}
                                multipleChoiceAnswerCheck={multipleChoiceAnswerCheck}
                                showingAnswer={showingAnswer}
                                correctAnswer={correctAnswer}
                            />

                            <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 mt-3 sm:mt-4 md:mt-6 lg:mt-8">
                                <button
                                    onClick={handleShowContextHint}
                                    disabled={showingAnswer || isCorrect === false}
                                    className={`bg-white border border-darkBlueColor text-darkBlueColor font-bold py-1 sm:py-2 md:py-3 lg:py-4 px-1 sm:px-2 md:px-3 lg:px-4 rounded shadow flex-1 ${showingAnswer || isCorrect === false
                                        ? 'opacity-50 cursor-not-allowed'
                                        : 'hover:bg-darkBlueColor hover:text-white'
                                        }`}
                                >
                                    <div className="flex items-center justify-center gap-1">
                                        <MdKeyboardTab className={`${showingAnswer || isCorrect === false
                                            ? 'text-darkBlueColor'
                                            : 'group-hover:text-white'}`} />
                                        <span>Show Context Hint</span>
                                    </div>
                                </button>
                                <button
                                    onClick={handleShowAnswer}
                                    disabled={isCorrect === false && !showingAnswer || isCorrect === true}
                                    className={`font-bold py-1 sm:py-2 md:py-3 lg:py-4 px-1 sm:px-2 md:px-3 lg:px-4 rounded shadow flex-1 ${showingAnswer
                                        ? 'bg-darkBlueColor text-white'
                                        : isCorrect === false && !showingAnswer || isCorrect === true
                                            ? 'bg-white border border-darkBlueColor text-darkBlueColor opacity-50 cursor-not-allowed'
                                            : 'bg-white border border-darkBlueColor text-darkBlueColor hover:bg-darkBlueColor hover:text-white'
                                        }`}
                                >
                                    {showingAnswer ? "Next Question" : "Show Answer"}
                                </button>
                                <button
                                    onClick={handleSkip}
                                    disabled={isSkipping || showingAnswer || isCorrect === false}
                                    className={`font-bold py-1 sm:py-2 md:py-3 lg:py-4 px-1 sm:px-2 md:px-3 lg:px-4 rounded shadow flex-1 ${isSkipping || showingAnswer || isCorrect === false
                                        ? 'bg-white border border-darkBlueColor text-darkBlueColor opacity-50 cursor-not-allowed'
                                        : 'bg-white border border-darkBlueColor text-darkBlueColor hover:bg-darkBlueColor hover:text-white'
                                        }`}
                                >
                                    Skip
                                </button>
                                <button
                                    onClick={toggleKeyboard}
                                    disabled={showingAnswer || isCorrect === false}
                                    className={`bg-white border border-darkBlueColor text-darkBlueColor font-bold py-1 sm:py-2 md:py-3 lg:py-4 px-1 sm:px-2 md:px-3 lg:px-4 rounded shadow flex-1 ${showingAnswer || isCorrect === false
                                        ? 'opacity-50 cursor-not-allowed'
                                        : 'hover:bg-darkBlueColor hover:text-white'
                                        }`}
                                >
                                    {showKeyboard ? 'Hide Keyboard' : 'Show Keyboard'}
                                </button>
                                <button
                                    onClick={() => setUseKatakana(!useKatakana)}
                                    disabled={showingAnswer || isCorrect === false}
                                    className={`${useKatakana ? 'bg-darkBlueColor text-white' : 'bg-white border border-darkBlueColor text-darkBlueColor'
                                        } ${showingAnswer || isCorrect === false
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'hover:bg-darkBlueColor hover:text-white'
                                        } font-bold py-1 sm:py-2 md:py-3 lg:py-4 px-1 sm:px-2 md:px-3 lg:px-4 rounded shadow flex-1 transition-colors duration-300`}
                                >
                                    {useKatakana ? 'Switch to Hiragana' : 'Switch to Katakana'}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

                {showFlashcard && flashcardData && (
                    <div className="w-full sm:max-w-full md:max-w-4xl lg:max-w-6xl mx-auto">
                    <div className="flex flex-col space-y-2 p-4 mb-4">
                        <div className="mt-2 text-left">
                            {(onScreenQuestion.type.toLowerCase().includes('kunyomi') || onScreenQuestion.type.toLowerCase().includes('onyomi')) && (
                                <div
                                    className={`bg-${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'orange-100' : 'lightBlueBackground'
                                        } p-6 rounded-lg shadow-md space-y-4 w-full`}
                                >
                                    <h3
                                        className={`text-xl font-bold ${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-800' : 'text-lightBlueText'
                                            }`}
                                    >
                                        {onScreenQuestion.type.toLowerCase().includes('kunyomi') ? "Kun'yomi" : "On'yomi"}
                                    </h3>
                                    <div className="space-y-2">
                                        <p
                                            className={`${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-600' : 'text-darkBlueColor'
                                                } text-sm`}
                                        >
                                            Vocabulary Word
                                        </p>
                                        <div className="flex flex-col space-y-1">
                                            <div
                                                className={`text-3xl font-medium ${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-900' : 'text-lightBlueText'
                                                    }`}
                                            >
                                                {flashcardData && flashcardData.vocabWord && flashcardData.vocabWord !== 'N/A' 
                                                    ? flashcardData.vocabWord.replace(/\/\/.*?\/\//g, '') 
                                                    : ''}
                                            </div>
                                            <p
                                                className={`${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-700' : 'text-darkBlueColor'
                                                    } text-xl`}
                                            >
                                                {flashcardData && flashcardData.vocabWord && flashcardData.vocabWord !== 'N/A'
                                                    ? flashcardData.vocabWordReading
                                                    : 'This reading has no associated vocabulary or sample sentences.'}
                                            </p>
                                            <div
                                                className={`text-2xl ${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-700' : 'text-darkBlueColor'
                                                    }`}
                                            >
                                                {flashcardData && flashcardData.vocabWord && flashcardData.vocabWord !== 'N/A' 
                                                    ? flashcardData.vocabWordMeaning 
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    {flashcardData.sampleSentence && flashcardData.vocabWord !== 'N/A' && (
                                        <div className="space-y-2 mt-4">
                                            <p className={`${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-600' : 'text-darkBlueColor'} text-sm`}>
                                                Sample Sentence
                                            </p>
                                            <div className={`text-2xl font-medium ${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-900' : 'text-lightBlueText'}`}>
                                                {flashcardData.furiganaSentence || flashcardData.sampleSentence}
                                            </div>
                                            <div className={`${onScreenQuestion.type.toLowerCase().includes('kunyomi') ? 'text-orange-700' : 'text-darkBlueColor'} text-xl`}>
                                                {flashcardData.sampleSentenceMeaning}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                )}


            {showKeyboard && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg max-w-3xl max-h-full overflow-auto">
                        <img
                            src={hiraganaKeyboardImage}
                            alt="Hiragana Keyboard Mapping"
                            className="w-full h-auto"
                        />
                        <button
                            onClick={toggleKeyboard}
                            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
            
            {loading ? (
                <div className="text-center space-y-4">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            ) : (
                quizFinished && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="text-center p-6 sm:p-8 md:p-10 lg:p-12 bg-white border-4 border-darkBlueColor rounded-2xl shadow-2xl max-w-3xl mx-auto">
                            <img
                                src={oniKanjiReviewsCompletedImage}
                                alt="Lesson Completed"
                                className="w-48 sm:w-56 md:w-64 lg:w-72 mx-auto mb-6"
                            />
                            <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 text-darkBlueColor">
                                Quiz Completed
                            </h2>
                            <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-8 text-gray-700">
                                お疲れ様です。
                            </p>
                            <button
                                onClick={handleReturnHome}
                                className="bg-white border-2 border-darkBlueColor text-darkBlueColor font-bold py-3 sm:py-4 md:py-5 lg:py-6 px-6 sm:px-8 md:px-10 lg:px-12 rounded-lg shadow-lg hover:bg-darkBlueColor hover:text-white transition-all duration-300 transform hover:scale-105"
                            >
                                {loadingHome ? (
                                    <ClipLoader size={24} color={"#014156"} loading={loadingHome} />
                                ) : (
                                    "Return to Home"
                                )}
                            </button>
                        </div>
                    </div>
                )
            )}
            <ToastContainer />
        </div>
    );
};
export default MainQuizEngine;
