import React from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const CriticalKanjiDisplay = ({ criticalKanji, isLoading, error }) => {
  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <ClipLoader color="#014156" className="mr-2" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-600 p-4 text-center">
        <div className="font-bold mb-2">Error loading critical kanji</div>
        <div className="text-sm">Please try refreshing the page</div>
      </div>
    );
  }

  if (!Array.isArray(criticalKanji)) {
    return (
      <div className="text-red-600 p-4 text-center">
        Invalid data format received
      </div>
    );
  }

  const message = criticalKanji.length > 0
    ? `Found ${criticalKanji.length} kanji in critical condition`
    : "No Kanji in critical status. Keep up the hard work!";

  return (
    <div className="max-w-screen-lg w-full mx-auto">
      <div className="text-lg mb-4">
        {message}
      </div>
      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-2 mt-6">
        {criticalKanji.slice(0, 20).map((kanji) => (
          <Link 
            to={`/kanji/${encodeURIComponent(kanji.kanji)}`} 
            key={kanji.id || kanji.kanji} 
            className="flex flex-col items-center p-2 bg-white border rounded-lg shadow hover:bg-darkBlueColor hover:text-white transition duration-300"
          >
            <div className="text-3xl font-bold mb-1">
              {kanji.kanji}
            </div>
            <div className="text-sm text-ellipsis overflow-hidden w-full text-center">
              {Math.round(kanji.totalAccuracy * 100)}% Accuracy
            </div>
          </Link>
        ))}
        {criticalKanji.length > 20 && (
          <div className="flex flex-col items-center p-2 bg-white border rounded-lg shadow">
            <div className="text-lg text-gray-600">+{criticalKanji.length - 20} more</div>
          </div>
        )}
      </div>
    </div>
  );
};