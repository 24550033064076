import React from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const KanjiStatusDisplay = ({ statusKanji, isStatusKanjiLoading, selectedStatuses }) => {
    if (isStatusKanjiLoading) {
        return (
            <div className="flex justify-center items-center h-full">
                <ClipLoader color="#014156" className="mr-2" />
            </div>
        );
    }

    if (!statusKanji || statusKanji.length === 0) {
        return <p className="text-gray-600">No kanji found for selected status(es)</p>;
    }

    return (
        <div className="max-w-screen-lg w-full mx-auto">
            <div className="text-lg mb-4">
                Selected {statusKanji.length} kanji with status: {selectedStatuses.join(', ')}
            </div>
            <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-2 mt-6">
                {statusKanji.slice(0, 20).map((item, index) => (
                    <Link
                        to={`/kanji/${encodeURIComponent(item.kanji)}`}
                        key={index}
                        className="flex flex-col items-center p-2 bg-white border rounded-lg shadow hover:bg-darkBlueColor hover:text-white transition duration-300"
                    >
                        <div className="text-3xl font-bold mb-1">
                            {item.kanji}
                        </div>
                        <div className="text-sm text-ellipsis overflow-hidden w-full text-center">
                            {item.status}
                        </div>
                    </Link>
                ))}
                {statusKanji.length > 20 && (
                    <div className="flex flex-col items-center p-2 bg-white border rounded-lg shadow">
                        <div className="text-lg text-gray-600">+{statusKanji.length - 20} more</div>
                    </div>
                )}
            </div>
        </div>
    );
};
