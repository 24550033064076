import { useQuery } from '@tanstack/react-query';
import { getFirestore, collection, query, where, orderBy, getDocs } from 'firebase/firestore';

export function useKanjiByLevels(includedLevels) {
  return useQuery({
    queryKey: ['kanjiByLevels', includedLevels],
    queryFn: async () => {
      const db = getFirestore();
      const kanjiCollection = collection(db, 'allKanji');
      
      // Convert numbers to strings for comparison
      const levelStrings = includedLevels.map(level => level.toString());
      
      const q = query(
        kanjiCollection,
        where('level', 'in', levelStrings),
        orderBy('lexicographicKanjiID', 'desc')
      );

      const querySnapshot = await getDocs(q);
      // Only return specific fields we need rather than all doc data
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        kanji: doc.data().kanji,
        level: doc.data().level,
        lexicographicKanjiID: doc.data().lexicographicKanjiID
        // Add other specific fields as needed
      }));
    },
    // Only fetch if we have levels to query
    enabled: includedLevels.length > 0
  });
}